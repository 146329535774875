@charset "UTF-8";

@import "_normalize";

$text: #333;
$main: #ee859a;
$sub: #f4eceb;
$accent: #ee264e;
$gold: #a78754;

@mixin iphonex{
    padding-right: constant(safe-area-inset-left);
    padding-left: constant(safe-area-inset-left);
    padding-right: env(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
}
img{
  	height: auto;
  	max-width: 100%;
}
iframe{
	  max-width: 100%	;
}
.mbl{
    @media(min-width:489px){
        display: none !important;
  }
}
.sp{
    @media(min-width:804px){
        display: none !important;
  }
}
.pc{
    @media(max-width:803px){
        display: none !important;
  }
}
.tblt{
    @media(min-width:900px){
        display: none !important;
  }
}
.lpc{
    @media(max-width:899px){
        display: none !important;
  }
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}
a{
  	text-decoration: none;
  	transition: .5s;
  	&:hover{
  		  opacity: .6;
	}
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

:focus{
    border:0;
    outline:none;
    box-shadow:none;
}
main{
    display: block;
}




body {
    min-width:300px;
    font-family: 'Noto Sans JP','Hiragino Kaku Gothic Pro','hiragino kaku gothic pro','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;
    line-height: 1.75;
    text-align: justify;
    text-justify: inter-ideograph;
    font-size: 15px;
    color:#545251;
    &.home{
    	background:#6F7DA1;
    	color: #fff;
    	a{
        color: #fff;
      }
    }
}





.top{
	position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
	&__navi{
		position: absolute;
	    top: 15px;
	    right: 15px;
	    background: none;
	    border: none;
	    padding: 0;
	    margin: 0;
	    cursor: pointer;
		span{
			display: block;
			width: 30px;
			height: 1px;
			background: #fff;
			margin-bottom: 10px;
			box-shadow: 1px 1px 1px #777;
		}
		@media(min-width:803px){
			top: 40px;
			right: 40px;
		}
	}
	&__menu{
		display: none;
		background: rgba(#6F7DA1,.9);
	    width: 100%;
	    height: 100vh;
	    ul{
	    	padding: 0;
	    	margin: 0;
	    	display: inline-block;
	    	position: relative;
    		z-index: 2;
	    	li{
	    		display: block;
	    		width: 296px;
	    		margin-bottom: 2em;
	    		font-size: 16px;
	    		@media(min-width:803px){
	    			width: 483px;
	    			margin-bottom: 3em;
	    		}
	    		a{
	    			color: #fff;
	    		}
	    	}
	    }
		&.open{
			display: flex;
		    align-items: center;
		    justify-content: center;
		}
	}
}

.home-link{
  font-size:14px;
  color: #95a7d5;
  font-weight: 700;
  position: fixed;
  top: 1em;
  left: 1em;
  line-height: 1.5;
  letter-spacing: 3px;
  z-index: 2;
  @media(min-width:768px){
    font-size: 20px;
  }
}



.close-button{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 2em;
    opacity: .8;
    img{
        width: 296px;
        height: 19px; 
    }
    @media(min-width:803px){
        img{
            width: 483px;
            height: 31px;
        }
    }
    
}





.top-image {
    background: url(../img/background-100.jpg) center / cover no-repeat;
    max-height: 680px;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    img{
        width: 296px;
        height: 19px; 
    }
    @media(min-width:803px){
        padding: 40px;
        img{
            width: 483px;
            height: 31px;
        }
    }
}





.wrapper{
    max-width:302px;
    margin: 0 auto;
    padding:0 0 60px 0;
    text-align:center;
    >div{
    	  display: inline-block;
    	  text-align: left;
    	  padding: 15px;
    	  letter-spacing:0.1em;
    	  h1{
      	    margin: 3em 0 1em 0;
      	    font-size: 20px;
    	  }
    	  p{
      	    line-height:2.5;
            margin: 2em 0 0 0;
            span{
                font-size:.73em;
                letter-spacing: 0.3em;
            }
    	  }
    }
    img{
        width:176px;
        height: 88px;
        float: right;
        margin: 20px 5% 0;
    }
    @media(min-width:803px){
        max-width: 513px;
        >div{
            h1{
                font-size: 45px;
            }
            p{
                font-size: 28px;
            }
        }
        img{
            transform-origin: right top;
            transform: scale(1.5);
            margin-bottom: 20px;
        }
    }
}




	  
.toppage-bottom{
    width:calc(100% - 64px);
    border-top:2px solid;
    margin: 46px auto 0 ;
    padding:20px 0;
    text-align:center;
    font-size:10px;
  
    dl{
        display:flex;
        dd{
            margin: 0;
        }
    }
    &__address{
        margin-bottom:10px;
      	text-align: left;
        letter-spacing: 0.1em;
        font-style:normal;
        &--text{
            font-size:1.6em;
            margin:0 0 0.3em 0;
        }
        &--time{
            font-size:1.25em;
            margin:0;
        }
        &--tel{
            font-size:2.3em;
            margin:0;
            dt{
                margin-right:0.5em;
            }
        }
        &--mail{
            font-size:1.45em;
            margin:0;
            dt{
                margin-right:0.5em;
            }
        }
        &--detail{
            font-size:1em;
            line-height:1.8em;
            margin:0.7em 0 0 0;
        }
    }
    &__map{
        width:100%;
        height:175px;
        margin-top:10px;
    }
    @media(min-width:803px){
        max-width:935.3px;
        display:flex;
        justify-content:space-between;
        font-size:14.8px;
        padding: 36px 0 108px;
        >*{
            width: 48%;
        }
        &__map {
            height: 250px;
            margin: 0;
        }
    }
}





.wrap{
    max-width: 1000px;
    margin: 0 auto;
    @media(min-width:803px){
        .wrap{
            &__introduction{
                margin: 45px auto 50px;
                h1{
                    font-size: 30px;
                }
            }
        }
        
    }
    
    
//.wrap内    
    
    
    &__introduction{
        text-align:center;
        >div{
            width:calc(100% - 3em);
            display: inline-block;
            text-align:left;
            margin:32px 0 0 0;
            border:3px solid #0960AE;
            transform:skewX(173deg);
            >h1{
                font-size:16px;
                color:#0960AE;
                line-height:2em;
                >span{
                    display:block;
                }
                padding:0 2em;
                transform:skewX(-173deg);
            }
        }
    }
    
//.wrap内    
    
    &__contents{
        width: calc(100% - 64px);
        margin:46px auto 0;
        font-size:1em;
        margin: 32px auto;
        h2{
            font-size: 16px;
            margin-top:44px;
        }
        dt{
            display: inline;
        }
        dd{
            margin: 0;
            display: inline;
        }
        ul{
            margin-bottom:0;
            list-style:none;
            padding-left:0;
            li {
                display: table-row;
            }
        }
        ul li:after{
            content: "";
            display: block;
            margin-bottom: 0.5em;
        }
        li:before {
            content: "•";
            display: table-cell;
            padding-right: 0.4em;
        }
        .bluetext{
            font-size:20px;
            color: #0960AE;
            font-weight:bold;
            margin-bottom:20px;
            >p{
                margin:6px;
            }
        }
        table{
            width: 100%;
            margin-top: 25px;
            border-collapse:collapse;
            th, td{
                border: solid 2px #9FA0A0;
                padding: 10px;
            }
            tr{
                margin:20px 0;
            }
            .vertical{
                background-color:#0960AE;
                color:#fff;
                font-weight: 400;
                width: 30px;
                word-break: break-all;
                td::before{
                    content: url(../img/checkbox.jpg);
                }
            }
  
            .horizontal{
                width: 97px;
                background-color: #0960AE;
                color: #fff;
                font-weight: 400;
                text-align: center;
            }
        }
        &--right{
            table{
                width:100%;
                text-align:center;
                border-collapse:collapse;
                font-size:12px;
                th, td{                
                    border: solid 2px #9FA0A0;
                }
                thead{
                    th:first-child{
                        width:26%;
                        background-color:#0960AE;
                        color:#fff;
                        line-height:38px;
                    }
                    th:nth-child(2){
                        width:37%;
                        background-color:#F19DAE;
                        color:#fff;
                    }
                    th:last-child{
                        width:37%;
                        background-color:#0960AE;
                        color:#fff;
                    }
                }
                tbody th{
                    background:#fff;
                    color:#545251;
                    font-weight: 400;
                    line-height:38px;
                }
            }
        }
        &--rightbottom{
            text-align:center;
        }
        .bluetext{
            font-size:25px;
        }
        .workflow{
            text-align:center;
            margin-top:50px;
        }
        @media(min-width:921px){
            &--right{
                table{
                    width: 500px;
                }
            }
        }
    }//&__contents    
    
//.wrap内

    @media(min-width:803px){
        .flex{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &__box{
                width: 48%;
            }
            li{
                width:32%;
            }
        }
        .workflow{
            margin-top:30px;
        }
    }

//.wrap内   

// recuruit.html （定員等記載の表）//
    &__frame{
        text-align:center;
        &--inner{
            width: calc(100% - 64px);
            border:2px solid #9FA0A0;
            margin:26px auto;
            text-align:left;
            padding:3px 2em;
            dl{
                line-height:2.4em;
            }
            dt{
                color: #0960AE;
            }
            dd{
                margin-inline-start: 0;
            }
        }
        @media(min-width:803px){
            &--inner{
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                dl{
                    padding: 1em;
                    margin: 0;
                    &:first-child{
                        width: 45%;
                        border-right: 1px solid;
                        border-bottom: 1px solid;
                    }
                    &:nth-child(2){
                        width: 55%;
                        border-bottom: 1px solid;
                    }
                    &:last-child{
                        width: 100%;
                        dd{
                            text-align: center;
                            img{
                                max-width: 570px;
                            }
                        }
                    }
                }
            }
        }
    }//&__frame　　recuruit.html（定員等記載の表）
    
//.wrap内    
    
    // recuruit.html  (ソマチッドの3つのポイント) //
    .number-list{
        counter-reset: my-counter;
        list-style: none;
        padding: 0;
        margin: 0;
        text-align:center;
        width:100%;
        li{
            margin:32px auto 0;
            padding: 34px 0 28px;
            border:3px solid #0960AE;
            transform:skewX(173deg);
            height: 211px;
            p{
                transform:skewX(-173deg);
                margin:0;
                font-weight:bold;
            }
            &:before {
                transform:skewX(-173deg);
                content: counter(my-counter);
                counter-increment: my-counter;
                display: block;
                font: bold 37px serif;
                color:#0960AE;
            }
        }
    }
    .number-list02{
        li{
            padding: 26px 0 28px;
        }
        .fourlines{
            margin:-8px;
        }
    }
}//.wrap

.flexposition{
  @media(min-width: 768px){
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: relative;
    &--left{
      h2{
        margin-top: 0;
      }
    }
    &--rightbottom{
      position: absolute;
      right: 0;
      bottom: 30px;
      width: 50%;
      text-align: center;
    }
  }
}



.image-wrap{
    display:flex;
    justify-content:space-between;
}





.catch{
    background-color: #0960AE;
    transform:skewX(173deg);
    font-size:15px;
    >p{
        font-size:1em;
        color:#fff;
        padding:0.5em 2em;
        margin:0;
        transform:skewX(-173deg);
        >strong{
            font-size:1.2em;
        }
    }
}





.clientpage-flow02{
    &__inner{
        width:70%;
        margin:0 auto;
        img{
            margin-top:10px;
        }
    }
}





.outline{
    border-bottom:2px solid #545251;
    padding-bottom:46px;
    .catch{
        margin-top:45px;
    }
}





.dotted{
    border-bottom:2px dotted #545251;
}





.bottom{
    max-width:1000px;
    width:calc(100% - 64px);
    margin:0 auto;
    text-align:center;
    font-size:10px;
    padding-bottom: 30px;
    dl{
        display:flex;
        dd{
            margin: 0;
        }
    }
    &__address{
        margin-bottom:10px;
      	text-align: left;
        letter-spacing: 0.1em;
        font-style:normal;
        &--text{
            font-size:1.6em;
            font-weight:bold;
            margin:0 0 0.3em 0;
        }
        &--time{
            font-size:1.25em;
            font-weight:bold;
            margin:0;
        }
        &--tel{
            font-size:2.3em;
            margin:0;
            color:#0960AE;
            a{
                color:#0960AE;
            }
            dt{
                margin-right:0.5em;
            }
        }
        &--mail{
            font-size:1.45em;
            margin:0;
            color:#0960AE;
            a{
                color:#0960AE;
            }
            dt{
                margin-right:0.5em;
            }
        }
        &--detail{
            font-size:1em;
            line-height:1.8em;
            margin:0.7em 0 0 0;
        }
    }
    &__map{
        width:100%;
        height:175px;
        margin-top:10px;
    }
    @media(min-width:803px){
        max-width:935.3px;
        display:flex;
        justify-content:space-between;
        font-size:14.8px;
        padding-bottom: 108px;
        >*{
            width: 48%;
        }
        &__map {
            height: 250px;
            margin: 0;
        }
    }
}//.bottom





//contact
.contact-title{
    color: #0960ae;
}
.form-rows{
    text-align: left;
    font-size: 16px;
    dt{
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      label{
        font-weight: bold;
      }
      span{
        padding: 0 5px;
        color: #fff;
        background: #0960AE;
      }
    }
    dd{
      display: block;
      textarea,input{
        width: 100%;
        padding: 5px 10px;
        border-radius: none;
        &:focus{
          border: 1px solid #0960AE;
        }
      }
      textarea{
        padding: 10px;
      }
    }
    @media(min-width:803px){
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 1.5em 0;
      dt{
          width: 200px;
          margin: 0;
          padding: 7px 0;
      }
      dd{
          width: calc(100% - 230px);
          input{
            padding: 10px;
          }
          textarea{
            height: 200px;
          }
      }
    }
}
.terms{
  max-width: 750px;
  margin: 40px auto;
  &__agree{
    max-width: 680px;
    margin: 30px auto 0;
    display: block;
    text-align: center;
    width: 100%;
    padding: 15px 0;
    background: transparent;
    border: 2px solid #0960ae;
    cursor: pointer;
    span{
      font-weight: bold;
      font-size: 2em;
      line-height: .1;
      border: 1px solid;
      height: 18px;
      width: 18px;
      display: none;
    }
    &.check{
      border: none;
      white-space: nowrap;
      span{
        display: inline-block;
      }
    }
  }
}
.submit-area{
  margin: 80px 0;
  position: relative;
  button{
    display: block;
    margin: 0 auto;
    background: #0960ae;
    border: none;
    color: #fff;
    padding: 30px 0;
    width: 100%;
    max-width: 500px;
    cursor: pointer;
  }
  &:after{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:2;
  }
  &.check{
    &:after{
      z-index: -1;
    }
  }
}

//contact
.privacy-title{
    color: #0960ae;
}
.wrap__contents.privacy-wrap{
  line-height: 2;
  p{
    margin-bottom: 3em;
  }
  dl{
    margin-bottom: 3em;
  }
  dt{
    margin-left: -10px;
  }
  dt,dd{
    display: block;
  }
  dd{
    margin-bottom: 1em;
  }
}
