@charset "UTF-8";
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
  margin: 0;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: .8em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  /* 3 */
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

img {
  height: auto;
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

@media (min-width: 489px) {
  .mbl {
    display: none !important;
  }
}

@media (min-width: 804px) {
  .sp {
    display: none !important;
  }
}

@media (max-width: 803px) {
  .pc {
    display: none !important;
  }
}

@media (min-width: 900px) {
  .tblt {
    display: none !important;
  }
}

@media (max-width: 899px) {
  .lpc {
    display: none !important;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: .5s;
}

a:hover {
  opacity: .6;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

:focus {
  border: 0;
  outline: none;
  box-shadow: none;
}

main {
  display: block;
}

body {
  min-width: 300px;
  font-family: 'Noto Sans JP','Hiragino Kaku Gothic Pro','hiragino kaku gothic pro','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;
  line-height: 1.75;
  text-align: justify;
  text-justify: inter-ideograph;
  font-size: 15px;
  color: #545251;
}

body.home {
  background: #6F7DA1;
  color: #fff;
}

body.home a {
  color: #fff;
}

.top {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.top__navi {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.top__navi span {
  display: block;
  width: 30px;
  height: 1px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px #777;
}

@media (min-width: 803px) {
  .top__navi {
    top: 40px;
    right: 40px;
  }
}

.top__menu {
  display: none;
  background: rgba(111, 125, 161, 0.9);
  width: 100%;
  height: 100vh;
}

.top__menu ul {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
  z-index: 2;
}

.top__menu ul li {
  display: block;
  width: 296px;
  margin-bottom: 2em;
  font-size: 16px;
}

@media (min-width: 803px) {
  .top__menu ul li {
    width: 483px;
    margin-bottom: 3em;
  }
}

.top__menu ul li a {
  color: #fff;
}

.top__menu.open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-link {
  font-size: 14px;
  color: #95a7d5;
  font-weight: 700;
  position: fixed;
  top: 1em;
  left: 1em;
  line-height: 1.5;
  letter-spacing: 3px;
  z-index: 2;
}

@media (min-width: 768px) {
  .home-link {
    font-size: 20px;
  }
}

.close-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 2em;
  opacity: .8;
}

.close-button img {
  width: 296px;
  height: 19px;
}

@media (min-width: 803px) {
  .close-button img {
    width: 483px;
    height: 31px;
  }
}

.top-image {
  background: url(../img/background-100.jpg) center/cover no-repeat;
  max-height: 680px;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
}

.top-image img {
  width: 296px;
  height: 19px;
}

@media (min-width: 803px) {
  .top-image {
    padding: 40px;
  }
  .top-image img {
    width: 483px;
    height: 31px;
  }
}

.wrapper {
  max-width: 302px;
  margin: 0 auto;
  padding: 0 0 60px 0;
  text-align: center;
}

.wrapper > div {
  display: inline-block;
  text-align: left;
  padding: 15px;
  letter-spacing: 0.1em;
}

.wrapper > div h1 {
  margin: 3em 0 1em 0;
  font-size: 20px;
}

.wrapper > div p {
  line-height: 2.5;
  margin: 2em 0 0 0;
}

.wrapper > div p span {
  font-size: .73em;
  letter-spacing: 0.3em;
}

.wrapper img {
  width: 176px;
  height: 88px;
  float: right;
  margin: 20px 5% 0;
}

@media (min-width: 803px) {
  .wrapper {
    max-width: 513px;
  }
  .wrapper > div h1 {
    font-size: 45px;
  }
  .wrapper > div p {
    font-size: 28px;
  }
  .wrapper img {
    transform-origin: right top;
    transform: scale(1.5);
    margin-bottom: 20px;
  }
}

.toppage-bottom {
  width: calc(100% - 64px);
  border-top: 2px solid;
  margin: 46px auto 0;
  padding: 20px 0;
  text-align: center;
  font-size: 10px;
}

.toppage-bottom dl {
  display: flex;
}

.toppage-bottom dl dd {
  margin: 0;
}

.toppage-bottom__address {
  margin-bottom: 10px;
  text-align: left;
  letter-spacing: 0.1em;
  font-style: normal;
}

.toppage-bottom__address--text {
  font-size: 1.6em;
  margin: 0 0 0.3em 0;
}

.toppage-bottom__address--time {
  font-size: 1.25em;
  margin: 0;
}

.toppage-bottom__address--tel {
  font-size: 2.3em;
  margin: 0;
}

.toppage-bottom__address--tel dt {
  margin-right: 0.5em;
}

.toppage-bottom__address--mail {
  font-size: 1.45em;
  margin: 0;
}

.toppage-bottom__address--mail dt {
  margin-right: 0.5em;
}

.toppage-bottom__address--detail {
  font-size: 1em;
  line-height: 1.8em;
  margin: 0.7em 0 0 0;
}

.toppage-bottom__map {
  width: 100%;
  height: 175px;
  margin-top: 10px;
}

@media (min-width: 803px) {
  .toppage-bottom {
    max-width: 935.3px;
    display: flex;
    justify-content: space-between;
    font-size: 14.8px;
    padding: 36px 0 108px;
  }
  .toppage-bottom > * {
    width: 48%;
  }
  .toppage-bottom__map {
    height: 250px;
    margin: 0;
  }
}

.wrap {
  max-width: 1000px;
  margin: 0 auto;
}

@media (min-width: 803px) {
  .wrap .wrap__introduction {
    margin: 45px auto 50px;
  }
  .wrap .wrap__introduction h1 {
    font-size: 30px;
  }
}

.wrap__introduction {
  text-align: center;
}

.wrap__introduction > div {
  width: calc(100% - 3em);
  display: inline-block;
  text-align: left;
  margin: 32px 0 0 0;
  border: 3px solid #0960AE;
  transform: skewX(173deg);
}

.wrap__introduction > div > h1 {
  font-size: 16px;
  color: #0960AE;
  line-height: 2em;
  padding: 0 2em;
  transform: skewX(-173deg);
}

.wrap__introduction > div > h1 > span {
  display: block;
}

.wrap__contents {
  width: calc(100% - 64px);
  margin: 46px auto 0;
  font-size: 1em;
  margin: 32px auto;
}

.wrap__contents h2 {
  font-size: 16px;
  margin-top: 44px;
}

.wrap__contents dt {
  display: inline;
}

.wrap__contents dd {
  margin: 0;
  display: inline;
}

.wrap__contents ul {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}

.wrap__contents ul li {
  display: table-row;
}

.wrap__contents ul li:after {
  content: "";
  display: block;
  margin-bottom: 0.5em;
}

.wrap__contents li:before {
  content: "•";
  display: table-cell;
  padding-right: 0.4em;
}

.wrap__contents .bluetext {
  font-size: 20px;
  color: #0960AE;
  font-weight: bold;
  margin-bottom: 20px;
}

.wrap__contents .bluetext > p {
  margin: 6px;
}

.wrap__contents table {
  width: 100%;
  margin-top: 25px;
  border-collapse: collapse;
}

.wrap__contents table th, .wrap__contents table td {
  border: solid 2px #9FA0A0;
  padding: 10px;
}

.wrap__contents table tr {
  margin: 20px 0;
}

.wrap__contents table .vertical {
  background-color: #0960AE;
  color: #fff;
  font-weight: 400;
  width: 30px;
  word-break: break-all;
}

.wrap__contents table .vertical td::before {
  content: url(../img/checkbox.jpg);
}

.wrap__contents table .horizontal {
  width: 97px;
  background-color: #0960AE;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.wrap__contents--right table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 12px;
}

.wrap__contents--right table th, .wrap__contents--right table td {
  border: solid 2px #9FA0A0;
}

.wrap__contents--right table thead th:first-child {
  width: 26%;
  background-color: #0960AE;
  color: #fff;
  line-height: 38px;
}

.wrap__contents--right table thead th:nth-child(2) {
  width: 37%;
  background-color: #F19DAE;
  color: #fff;
}

.wrap__contents--right table thead th:last-child {
  width: 37%;
  background-color: #0960AE;
  color: #fff;
}

.wrap__contents--right table tbody th {
  background: #fff;
  color: #545251;
  font-weight: 400;
  line-height: 38px;
}

.wrap__contents--rightbottom {
  text-align: center;
}

.wrap__contents .bluetext {
  font-size: 25px;
}

.wrap__contents .workflow {
  text-align: center;
  margin-top: 50px;
}

@media (min-width: 921px) {
  .wrap__contents--right table {
    width: 500px;
  }
}

@media (min-width: 803px) {
  .wrap .flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .wrap .flex__box {
    width: 48%;
  }
  .wrap .flex li {
    width: 32%;
  }
  .wrap .workflow {
    margin-top: 30px;
  }
}

.wrap__frame {
  text-align: center;
}

.wrap__frame--inner {
  width: calc(100% - 64px);
  border: 2px solid #9FA0A0;
  margin: 26px auto;
  text-align: left;
  padding: 3px 2em;
}

.wrap__frame--inner dl {
  line-height: 2.4em;
}

.wrap__frame--inner dt {
  color: #0960AE;
}

.wrap__frame--inner dd {
  margin-inline-start: 0;
}

@media (min-width: 803px) {
  .wrap__frame--inner {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  .wrap__frame--inner dl {
    padding: 1em;
    margin: 0;
  }
  .wrap__frame--inner dl:first-child {
    width: 45%;
    border-right: 1px solid;
    border-bottom: 1px solid;
  }
  .wrap__frame--inner dl:nth-child(2) {
    width: 55%;
    border-bottom: 1px solid;
  }
  .wrap__frame--inner dl:last-child {
    width: 100%;
  }
  .wrap__frame--inner dl:last-child dd {
    text-align: center;
  }
  .wrap__frame--inner dl:last-child dd img {
    max-width: 570px;
  }
}

.wrap .number-list {
  counter-reset: my-counter;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  width: 100%;
}

.wrap .number-list li {
  margin: 32px auto 0;
  padding: 34px 0 28px;
  border: 3px solid #0960AE;
  transform: skewX(173deg);
  height: 211px;
}

.wrap .number-list li p {
  transform: skewX(-173deg);
  margin: 0;
  font-weight: bold;
}

.wrap .number-list li:before {
  transform: skewX(-173deg);
  content: counter(my-counter);
  counter-increment: my-counter;
  display: block;
  font: bold 37px serif;
  color: #0960AE;
}

.wrap .number-list02 li {
  padding: 26px 0 28px;
}

.wrap .number-list02 .fourlines {
  margin: -8px;
}

@media (min-width: 768px) {
  .flexposition {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: relative;
  }
  .flexposition--left h2 {
    margin-top: 0;
  }
  .flexposition--rightbottom {
    position: absolute;
    right: 0;
    bottom: 30px;
    width: 50%;
    text-align: center;
  }
}

.image-wrap {
  display: flex;
  justify-content: space-between;
}

.catch {
  background-color: #0960AE;
  transform: skewX(173deg);
  font-size: 15px;
}

.catch > p {
  font-size: 1em;
  color: #fff;
  padding: 0.5em 2em;
  margin: 0;
  transform: skewX(-173deg);
}

.catch > p > strong {
  font-size: 1.2em;
}

.clientpage-flow02__inner {
  width: 70%;
  margin: 0 auto;
}

.clientpage-flow02__inner img {
  margin-top: 10px;
}

.outline {
  border-bottom: 2px solid #545251;
  padding-bottom: 46px;
}

.outline .catch {
  margin-top: 45px;
}

.dotted {
  border-bottom: 2px dotted #545251;
}

.bottom {
  max-width: 1000px;
  width: calc(100% - 64px);
  margin: 0 auto;
  text-align: center;
  font-size: 10px;
  padding-bottom: 30px;
}

.bottom dl {
  display: flex;
}

.bottom dl dd {
  margin: 0;
}

.bottom__address {
  margin-bottom: 10px;
  text-align: left;
  letter-spacing: 0.1em;
  font-style: normal;
}

.bottom__address--text {
  font-size: 1.6em;
  font-weight: bold;
  margin: 0 0 0.3em 0;
}

.bottom__address--time {
  font-size: 1.25em;
  font-weight: bold;
  margin: 0;
}

.bottom__address--tel {
  font-size: 2.3em;
  margin: 0;
  color: #0960AE;
}

.bottom__address--tel a {
  color: #0960AE;
}

.bottom__address--tel dt {
  margin-right: 0.5em;
}

.bottom__address--mail {
  font-size: 1.45em;
  margin: 0;
  color: #0960AE;
}

.bottom__address--mail a {
  color: #0960AE;
}

.bottom__address--mail dt {
  margin-right: 0.5em;
}

.bottom__address--detail {
  font-size: 1em;
  line-height: 1.8em;
  margin: 0.7em 0 0 0;
}

.bottom__map {
  width: 100%;
  height: 175px;
  margin-top: 10px;
}

@media (min-width: 803px) {
  .bottom {
    max-width: 935.3px;
    display: flex;
    justify-content: space-between;
    font-size: 14.8px;
    padding-bottom: 108px;
  }
  .bottom > * {
    width: 48%;
  }
  .bottom__map {
    height: 250px;
    margin: 0;
  }
}

.contact-title {
  color: #0960ae;
}

.form-rows {
  text-align: left;
  font-size: 16px;
}

.form-rows dt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.form-rows dt label {
  font-weight: bold;
}

.form-rows dt span {
  padding: 0 5px;
  color: #fff;
  background: #0960AE;
}

.form-rows dd {
  display: block;
}

.form-rows dd textarea, .form-rows dd input {
  width: 100%;
  padding: 5px 10px;
  border-radius: none;
}

.form-rows dd textarea:focus, .form-rows dd input:focus {
  border: 1px solid #0960AE;
}

.form-rows dd textarea {
  padding: 10px;
}

@media (min-width: 803px) {
  .form-rows {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 1.5em 0;
  }
  .form-rows dt {
    width: 200px;
    margin: 0;
    padding: 7px 0;
  }
  .form-rows dd {
    width: calc(100% - 230px);
  }
  .form-rows dd input {
    padding: 10px;
  }
  .form-rows dd textarea {
    height: 200px;
  }
}

.terms {
  max-width: 750px;
  margin: 40px auto;
}

.terms__agree {
  max-width: 680px;
  margin: 30px auto 0;
  display: block;
  text-align: center;
  width: 100%;
  padding: 15px 0;
  background: transparent;
  border: 2px solid #0960ae;
  cursor: pointer;
}

.terms__agree span {
  font-weight: bold;
  font-size: 2em;
  line-height: .1;
  border: 1px solid;
  height: 18px;
  width: 18px;
  display: none;
}

.terms__agree.check {
  border: none;
  white-space: nowrap;
}

.terms__agree.check span {
  display: inline-block;
}

.submit-area {
  margin: 80px 0;
  position: relative;
}

.submit-area button {
  display: block;
  margin: 0 auto;
  background: #0960ae;
  border: none;
  color: #fff;
  padding: 30px 0;
  width: 100%;
  max-width: 500px;
  cursor: pointer;
}

.submit-area:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.submit-area.check:after {
  z-index: -1;
}

.privacy-title {
  color: #0960ae;
}

.wrap__contents.privacy-wrap {
  line-height: 2;
}

.wrap__contents.privacy-wrap p {
  margin-bottom: 3em;
}

.wrap__contents.privacy-wrap dl {
  margin-bottom: 3em;
}

.wrap__contents.privacy-wrap dt {
  margin-left: -10px;
}

.wrap__contents.privacy-wrap dt, .wrap__contents.privacy-wrap dd {
  display: block;
}

.wrap__contents.privacy-wrap dd {
  margin-bottom: 1em;
}
